/** @format */

import { Box } from '@chakra-ui/layout';
import { NextSeo } from 'next-seo';
import Layout from '../../../components/layout';
import getFooter from '../../../lib/api/acf/footer';
import { getContentNode } from '../../../lib/api/contentNodes';
import { getSeoFields } from '../../../lib/getSeoFields';
import Patterns from '../../../patterns';
import mappingFooter from '../../../patterns/Footer/mappingFooter';
// import getMainMenu from '../../../lib/api/acf/mainMenu';
import { GetStaticPaths } from 'next';
import DraftModeUI from '../../../components/DraftModeUI/Index';
import {
	getGlobalMainMenu, getMainMenu, getParentSiteMainMenu
} from '../../../lib/api/acf/navBarMenu';
import mappingNavBar from '../../../patterns/NavBar/mappingNavBar';
import { identifySubdomain } from '../../../utils';

function Index({post, seo, navBar, footer, subdomain, draftMode}) {
	if (!post) return null;

	return (
		<Layout seo={seo} navBar={navBar} footer={footer} subdomain={subdomain}>
			<NextSeo {...seo} />
			<Box>
				{draftMode && <DraftModeUI />}
				{post.pageLayout.flexibleContent.map((post: any, index: number) => {
					return (
						<Patterns
							fieldGroupName={post.__typename}
							post={post}
							subdomain={subdomain}
							key={`${post.fieldGroupName}_${index}`}
						/>
					);
				})}
			</Box>
		</Layout>
	);
}

export const getStaticPaths: GetStaticPaths = async () => {
	// const allPosts = await getAllPostsWithSlug();
	// const allPosts = {
	// 	edges: [
	// 		{
	// 			node: {
	// 				slug: 'hello-world',
	// 			},
	// 		},
	// 	],
	// };

	// console.log('pages _sites [site] posts [slug] allPosts: ');
	return {
		paths: [],
		fallback: true,
	};
};

export async function getStaticProps(context) {
	let {params: {slug, site}, draftMode} = context;

	const subdomain = site || '';
	// const subdomain = 'college';

	const id = `${slug}`;
	const idType = 'URI';
	const post = await getContentNode(id, idType, draftMode, subdomain);

	const seo = !draftMode ? getSeoFields(post) : null;

	// Menus
	const globalMainMenu = await getGlobalMainMenu('');
	const mainMenu = await getMainMenu(subdomain);
	const parentSiteMenu = await getParentSiteMainMenu('');

	let navBar: any = [];

	if (mainMenu && globalMainMenu && parentSiteMenu) {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		navBar = mappingNavBar({globalMainMenu, parentSiteMenu, mainMenu});
	}

	let footer = await getFooter(subdomain);
	footer = mappingFooter(footer);

	const subdomainStr = identifySubdomain(post?.link);

	if(!draftMode) {
		draftMode = false;
	}

	// const allPosts = preview
	// 	? await getAllPostsForHome(preview, subdomain)
	// 	: defaultPosts;

	if (!post) return {notFound: true};

	return {
		props: {post, seo, navBar, footer, subdomain: subdomainStr, draftMode},
		revalidate: 150,
	};
}

export default Index;
