import { Box, Button, Text } from "@chakra-ui/react";

const DraftModeUI = () => {
    return (
        <Box bg="gray.100" p={4} rounded="md">
			<Text fontStyle="italic">
				You are previewing a draft.{" "}
				    <Button colorScheme="maroon" onClick={() => window.location.href = "/api/disable-draft"}>
					    exit preview mode
                    </Button>
			</Text>
		</Box>
    )
}

export default DraftModeUI;